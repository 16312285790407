import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const PostCard = ({ post }) => {
  const imageData =
    post.featImage && post.featImage.childImageSharp.gatsbyImageData;

  return (
    <Card className="bg-black bg-opacity-75 shadow-sm fg-fg rounded-0 post-card">
      <GatsbyImage image={imageData} alt={post.frontmatter.title} />
      <Card.Header className="border-bottom-0 fg-fg">
        <h2 className="h4 mt-2">{post.frontmatter.title}</h2>
        <em className="small">{post.frontmatter.description}</em>
      </Card.Header>
      <Card.Body className=" fg-fg">
        <p className="mb-0">{post.excerpt}</p>
        <Link
          to={post.frontmatter.slug}
          className="stretched-link"
          aria-label={post.frontmatter.title}
        />
      </Card.Body>
      <Card.Footer className="border-top-0 fg-fg p-3 pt-1">
        <small className="text-fade">{post.frontmatter.date}</small>
      </Card.Footer>
    </Card>
  );
};

export default PostCard;
