/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import BlogPostsList from "../../src/components/BlogPostsList";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components), {Container} = _components;
  if (!Container) _missingMdxReference("Container", true);
  return React.createElement(Container, null, React.createElement(_components.h1, null, "Blogi"), React.createElement("br"), React.createElement(_components.p, null, "Koska jokaisella kunnon devaajalla pitää tietenkin olla oma devaajablogi."), React.createElement(_components.p, null, "Ja koska tämä on ihan ite pienistä paloista kasattu lähinnä testimielessä, tämä\non vähän tällainen kehitysversio (niinhän ne aina). Mutta mikäs sen hauskempaa!\nTämänkin blogin kehityksestä voit lukea tästä samasta blogista!\n", React.createElement("sub", null, "(Ehkä).")), React.createElement(BlogPostsList));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
