import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import PostCard from "./PostCard";

const query = graphql`
  {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        id
        excerpt(pruneLength: 140)
        frontmatter {
          title
          slug
          date
          tags
          image
          description
          draft
        }
        featImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

const BlogPostsList = () => {
  const postsResult = useStaticQuery(query);

  let posts = postsResult && postsResult.allMdx.nodes;

  // filter out regular pages (no date)
  posts = posts.filter(p => {
    if (p.frontmatter.date != null) {
      p.frontmatter.date = new Date(p.frontmatter.date).toLocaleDateString(
        "fi-FI"
      );
    }
    return p.frontmatter.date != null && !p.frontmatter.draft;
  });

  return (
    <Row>
      {posts.length > 0 ? (
        posts.map((p, key) => {
          return (
            <Col key={key} xs={12} lg={4} className="g-4 d-flex mb-5">
              <PostCard post={p} />
            </Col>
          );
        })
      ) : (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: "50vh" }}
        >
          <h2>No eihän täällä ole vielä mitään.</h2>
        </div>
      )}
    </Row>
  );
};

export default BlogPostsList;
